import React from "react";
import { Spin, Icon } from "antd";

// Spin.setDefaultIndicator(<Icon type="loading" style={{ fontSize: 40 }} spin />);

function Loading({ size = 40, children, spin = true }) {
  return (
    <Spin
      spinning={spin}
      indicator={<Icon type="loading" style={{ fontSize: size }} spin />}
    >
      {children || null}
    </Spin>
  );
}

export default Loading;
