export const SELECTED_ROW_KEYS = "STAFF/SELECTED_ROW_KEYS";
export const SEARCH_PARAMS = "STAFF/SEARCH_PARAMS";
export const SEARCH_CONDITION = "STAFF/SEARCH_CONDITION";
export const LIST = "STAFF/LIST";
export const SHOW_EDIT = "STAFF/SHOW_EDIT";
export const DETAIL_FORM = "STAFF/DETAIL_FORM";
export const ALL_AUTHORITY_LIST = "STAFF/ALL_AUTHORITY_LIST";
export const PAGE = "STAFF/PAGE";
export const ROWS = "STAFF/ROWS";
export const FREE_SETTING = "STAFF/FREE_SETTING";
