import { message } from "antd";
import * as api from "@/api/authority";
import {
  SEARCH_PARAMS,
  SEARCH_CONDITION,
  LIST,
  SELECTED_ROW_KEYS,
  SHOW_EDIT,
  DETAIL_FORM,
  ALL_MENU_LIST,
  PAGE,
  ROWS,
  FREE_SETTING,
  AUTHORITY_SELECTOR
} from "./constants";

/**
 * 自由设置多个参数
 * @param {*} obj 对象键值对
 */
export const handleFreeSetting = obj => ({
  type: FREE_SETTING,
  obj
});

export const changeAuthoritySelector = val => ({
  type: AUTHORITY_SELECTOR,
  val
});

export const changePage = val => ({
  type: PAGE,
  val
});

export const changePageSize = val => ({
  type: ROWS,
  val
});

export const changeSelectedRowKeys = val => ({
  type: SELECTED_ROW_KEYS,
  val
});

export const changeSearchParams = val => ({
  type: SEARCH_PARAMS,
  val
});

export const changeSearchCondition = val => ({
  type: SEARCH_CONDITION,
  val
});

export const changeShowEdit = val => ({
  type: SHOW_EDIT,
  val
});

export const changeDetailForm = val => ({
  type: DETAIL_FORM,
  val
});

export const changeMenuList = val => ({
  type: ALL_MENU_LIST,
  val
});

export const changeList = (list, total) => ({
  type: LIST,
  list,
  total
});

/**
 * 获取列表
 */
export const getList = params => async dispatch => {
  try {
    const res = await api.getAuthorityList(params);
    if (res.code === 0) {
      const { data, total } = res.data;
      data.map(item => {
        item.key = item.id;
        return item;
      });
      dispatch(changeList(data, total));
    }
  } catch (error) {}
};

/**
 * 获取详情
 */
export const getLineDetail = id => dispatch =>
  new Promise(async resolve => {
    try {
      const res = await api.getAuthorityDetail({
        id
      });
      if (res.code === 0) {
        res.data.power_ids&&dispatch(
          changeAuthoritySelector(
            res.data.power_ids.split(",").map(item => +item)
          )
        );
        await dispatch(changeDetailForm(res.data));
      }
    } catch (error) {}
    resolve();
  });

/**
 * 更新详情信息
 */
export const updateEditForm = params =>
  new Promise(async resolve => {
    const res = await api.updateAuthority(params);
    if (res.code === 0) {
      message.success("修改成功");
      return resolve(true);
    }
    message.error(res.msg);
    resolve(false);
  });

/**
 * 创建详情信息
 */
export const createEditForm = params =>
  new Promise(async resolve => {
    const res = await api.createAuthority(params);
    if (res.code === 0) {
      message.success("创建成功");
      return resolve(true);
    }
    message.error(res.msg);
    resolve(false);
  });

/**
 * 删除事件
 * @param {array} ids ID数组
 */
export const handleDeleteLine = ids =>
  new Promise(async resolve => {
    const res = await api.deleteAuthorityItem({ id: ids.join(",") });
    if (res.code === 0) {
      message.success("删除成功");
      return resolve(true);
    }
    message.success(res.msg);
    resolve(false);
  });
