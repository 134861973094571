export const SELECTED_ROW_KEYS = "PROCUREMENT_COSTS/SELECTED_ROW_KEYS";
export const SEARCH_PARAMS = "PROCUREMENT_COSTS/SEARCH_PARAMS";
export const SEARCH_CONDITION = "PROCUREMENT_COSTS/SEARCH_CONDITION";
export const LIST = "PROCUREMENT_COSTS/LIST";
export const SHOW_EDIT = "PROCUREMENT_COSTS/SHOW_EDIT";
export const DETAIL_FORM = "PROCUREMENT_COSTS/DETAIL_FORM";
export const ALL_GOODS_LIST = "PROCUREMENT_COSTS/ALL_GOODS_LIST";
export const PAGE = "PROCUREMENT_COSTS/PAGE";
export const ROWS = "PROCUREMENT_COSTS/ROWS";
export const FREE_SETTING = "PROCUREMENT_COSTS/FREE_SETTING";
