import { COLLAPSED, LAYOUT_MENU, STORE_LIST, ALL_THEME } from "./constants";

const INITIAL_STATE = {
  collapsed: false,
  layoutMenu: [],
  storeList: null,
  allTheme: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORE_LIST:
      return {
        ...state,
        storeList: action.val
      };
    case ALL_THEME:
      return {
        ...state,
        allTheme: action.val
      };
    case COLLAPSED:
      return {
        ...state,
        collapsed: action.val
      };
    case LAYOUT_MENU:
      return {
        ...state,
        layoutMenu: [...action.val]
      };
    default:
      return state;
  }
};
