import request from "@/api/request";

/**
 * 人工费用列表
 */
export const getArtificialCostList = data => {
  return request({
    url: "/api/wages_select",
    method: "post",
    data
  });
};

/**
 * 人工费用详情
 */
export const getArtificialCostDetail = data => {
  return request({
    url: "/api/wages_detail",
    method: "post",
    data
  });
};

/**
 * 人工费用修改
 */
export const updateArtificialCost = data => {
  return request({
    url: "/api/wages_edit",
    method: "post",
    data
  });
};

/**
 * 人工费用新增
 */
export const createArtificialCost = data => {
  return request({
    url: "/api/wages_add",
    method: "post",
    data
  });
};

/**
 * 人工费用删除
 */
export const deleteArtificialCostItem = data => {
  return request({
    url: "/api/wages_delete",
    method: "post",
    data
  });
};
