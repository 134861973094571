import request from "@/api/request";

/**
 * 用户列表
 */
export const getUserList = data => {
  return request({
    url: "/api/investigation_select",
    method: "post",
    data
  });
};

/**
 * 用户详情
 */
export const getUserDetail = data => {
  return request({
    url: "/api/investigation_detail",
    method: "post",
    data
  });
};

/**
 * 用户修改
 */
export const updateUser = data => {
  return request({
    url: "/api/investigation_edit",
    method: "post",
    data
  });
};

/**
 * 用户新增
 */
export const createUser = data => {
  return request({
    url: "/api/investigation_add",
    method: "post",
    data
  });
};

/**
 * 用户删除
 */
export const deleteUserItem = data => {
  return request({
    url: "/api/investigation_delete",
    method: "post",
    data
  });
};

/**
 * 用户修改积分
 */
export const editUserIntegral = data => {
  return request({
    url: "/api/integral_edit",
    method: "post",
    data
  });
};