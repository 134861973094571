import request from "@/api/request";

/**
 * 采购费用列表
 */
export const getProcurementCostsList = data => {
  return request({
    url: "/api/procurement_select",
    method: "post",
    data
  });
};

/**
 * 采购费用详情
 */
export const getProcurementCostsDetail = data => {
  return request({
    url: "/api/procurement_detail",
    method: "post",
    data
  });
};

/**
 * 采购费用修改
 */
export const updateProcurementCosts = data => {
  return request({
    url: "/api/procurement_edit",
    method: "post",
    data
  });
};

/**
 * 采购费用新增
 */
export const createProcurementCosts = data => {
  return request({
    url: "/api/procurement_add",
    method: "post",
    data
  });
};

/**
 * 采购费用删除
 */
export const deleteProcurementCostsItem = data => {
  return request({
    url: "/api/procurement_delete",
    method: "post",
    data
  });
};
