import request from "./request";

/**
 * 获取主题列表
 */
export const getStatistics = data => {
  return request({
    url: "/api/statistics",
    method: "post",
    data
  });
};

/**
 * 获取主题营业额
 */
export const getThemeStatistics = data => {
  return request({
    url: "/api/theme_money",
    method: "post",
    data
  });
};

/**
 * 获取主题营业额
 */
export const getStoreStatistics = data => {
  return request({
    url: "/api/store_money",
    method: "post",
    data
  });
};
