import request from "@/api/request";

/**
 * 营销费用列表
 */
export const getMarketingExpensesList = data => {
  return request({
    url: "/api/marketing_select",
    method: "post",
    data
  });
};

/**
 * 营销费用详情
 */
export const getMarketingExpensesDetail = data => {
  return request({
    url: "/api/marketing_detail",
    method: "post",
    data
  });
};

/**
 * 营销费用修改
 */
export const updateMarketingExpenses = data => {
  return request({
    url: "/api/marketing_edit",
    method: "post",
    data
  });
};

/**
 * 营销费用新增
 */
export const createMarketingExpenses = data => {
  return request({
    url: "/api/marketing_add",
    method: "post",
    data
  });
};

/**
 * 营销费用删除
 */
export const deleteMarketingExpensesItem = data => {
  return request({
    url: "/api/marketing_delete",
    method: "post",
    data
  });
};
