import axios from "axios";
import { message } from "antd";
import { base_url } from "../config";
import { store } from "../store";

const service = axios.create({
  baseURL: base_url, // api 的 base_url
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
  }
  // timeout: 5000 // 请求超时时间
});

//request拦截器
service.interceptors.request.use(
  config => {
    if (config.url.indexOf("uploadImg") !== -1) {
      config.withCredentials = true;
      config.headers["Content-Type"] = {
        "Content-Type": "multipart/form-data"
      };
    } else if (config.method === "post") {
      const data = config.data || {};
      if (config.url.indexOf("login") === -1) {
        const { userMessage } = store.getState().login;
        if(userMessage){

          data.user_id = userMessage.user_id;
          data.access_token = userMessage.access_token;
          userMessage.position !== 1 &&
            userMessage.hasOwnProperty("store_id") &&
            userMessage.store_id &&
            (data.store_id = userMessage.store_id);
        }
      }
      // 序列化
      var str = "";
      for (var key in data) {
        str +=
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key]) + "&";
      }
      config.data = str.slice(0, str.length - 1);
    }
    return config;
  },
  error => {
    // Do something with request error
    Promise.reject(error);
  }
);

//响应拦截器
service.interceptors.response.use(
  function(response) {
    // 用户信息是否超时，重定向到登录页面
    if (response.data.code === 301) {
      window.location.href = "/login";
      sessionStorage.clear();
      message.error("登录信息已过期，请重新登录！", 3);
    }
    return response.data;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);
export default service;
