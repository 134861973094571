import {
  SELECTED_ROW_KEYS,
  PAGE,
  ROWS,
  LIST,
  SEARCH_CONDITION,
  SEARCH_PARAMS,
  SHOW_EDIT,
  DETAIL_FORM,
  FREE_SETTING
} from "./constants";

const INITIAL_STATE = {
  searchParams: {},
  searchCondition: null,
  page: 1,
  rows: 10,
  total: 0,
  list: [],
  selectedRowKeys: [],
  showEdit: false,
  detailForm: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAGE:
      return {
        ...state,
        page: action.val
      };
    case ROWS:
      return {
        ...state,
        rows: action.val,page:1
      };
    case SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: action.val && { ...action.val }
      };
    case SELECTED_ROW_KEYS:
      return {
        ...state,
        selectedRowKeys: action.val
      };
    case SHOW_EDIT:
      return {
        ...state,
        showEdit: action.val
      };
    case DETAIL_FORM:
      return {
        ...state,
        detailForm: action.val && { ...action.val }
      };
    case SEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.val && { ...action.val }
      };
    case LIST:
      return {
        ...state,
        list: [...action.list],
        total: action.total
      };
    case FREE_SETTING:
      return {
        ...state,
        ...action.obj
      };
    default:
      return state;
  }
};
