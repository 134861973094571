import {
  SHOW_EDIT_FORM,
  SHOW_GAME_DATA,
  SHOW_SETTLEMENT,
  SELECT_DATE,
  DETAIL_FORM,
  THEME_TIME_LIST,
  STORE_ID
} from "./constants";

const INITIAL_STATE = {
  storeId: undefined,
  themeTimeList: [], // 主题对应时间段列表
  selectDate: undefined, // 时间选择
  detailForm: null, // 详情
  showEditForm: false, // 显示预约编辑
  showGameData: false, // 显示游戏数据框
  showSettlement: false // 显示结算框
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_DATE:
      return {
        ...state,
        selectDate: action.val
      };
    case STORE_ID:
      return {
        ...state,
        storeId: action.val
      };
    case DETAIL_FORM:
      return {
        ...state,
        detailForm: action.val && { ...action.val }
      };
    case SHOW_SETTLEMENT:
      return {
        ...state,
        showSettlement: action.val
      };
    case SHOW_EDIT_FORM:
      return {
        ...state,
        showEditForm: action.val
      };
    case SHOW_GAME_DATA:
      return {
        ...state,
        showGameData: action.val
      };
    case THEME_TIME_LIST:
      return {
        ...state,
        themeTimeList: [...action.val]
      };
    default:
      return state;
  }
};
