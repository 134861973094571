import * as api from "@/api/fundStatistics";
import {
  STOREID,
  SETTLEMENT_LIST,
  SETTLEMENT_DATE,
  LINE_CARD_MSG,
  THEME_STATISTICS,
  STORE_STATISTICS,
  THEME_SELECTED_DATE,
  STORE_SELECTED_DATE
} from "./constants";

export const changeStoreId = val => ({
  type: STOREID,
  val
});

export const changeSettlementDate = val => ({
  type: SETTLEMENT_DATE,
  val
});

export const changeStoreSelectedDate = val => ({
  type: STORE_SELECTED_DATE,
  val
});

export const changeThemeSelectedDate = val => ({
  type: THEME_SELECTED_DATE,
  val
});

export const changeSettlementList = val => ({
  type: SETTLEMENT_LIST,
  val
});

export const changeThemeStatistics = val => ({
  type: THEME_STATISTICS,
  val
});

export const changeStoreStatistics = val => ({
  type: STORE_STATISTICS,
  val
});

export const changeLineCardMsg = val => ({
  type: LINE_CARD_MSG,
  val
});

/**
 * 获取收银统计
 */
export const getStatistics = params => async (dispatch, getState) => {
  const res = await api.getStatistics(params);
  if (res.code === 0) {
    const { day, sum } = res.data;
    if (!day || !sum) return;
    const data = [];
    day.map(item => {
      data.push({ time: item.time, money: +item.cash, type: "现金" });
      data.push({ time: item.time, money: +item.unionpay, type: "银联" });
      data.push({ time: item.time, money: +item.overtime_money, type: "加班" });
      data.push({
        time: item.time,
        money: +item.meituan_money,
        type: "美团预定"
      });
      data.push({
        time: item.time,
        money: +item.voucher_money,
        type: "大众/美团"
      });
      data.push({ time: item.time, money: +item.amount_money, type: "开票" });
      data.push({ time: item.time, money: +item.other_sum, type: "其他" });
      return item;
    });
    dispatch(changeLineCardMsg(sum));
    dispatch(changeSettlementList(data));
  }
};

/**
 * 获取主题营业额统计
 */
export const getThemeStatistics = params => async (dispatch, getState) => {
  const res = await api.getThemeStatistics(params);
  if (res.code === 0) {
    dispatch(changeThemeStatistics(res.data));
  }
};

/**
 * 获取主题营业额统计
 */
export const getStoreStatistics = params => async (dispatch, getState) => {
  const res = await api.getStoreStatistics(params);
  if (res.code === 0) {
    dispatch(changeStoreStatistics(res.data));
  }
};
