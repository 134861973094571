import { getCustomerBase } from "@/api";
import {
  CUSTOMER_DATA,
  DATE_RANGE,
  SEARCH_CONDITION,
  THEME_ID,
  STORE_ID
} from "./constants";

export const changeStoreId = val => ({
  type: STORE_ID,
  val
});

export const changeThemeId = val => ({
  type: THEME_ID,
  val
});

export const changeCustomerData = val => ({
  type: CUSTOMER_DATA,
  val
});

export const changeDateRange = val => ({
  type: DATE_RANGE,
  val
});

export const changeSearchCondition = val => ({
  type: SEARCH_CONDITION,
  val
});

export const getCustomerPageData = params => async dispatch => {
  const res = await getCustomerBase(params);
  if (res.code === 0) {
    const data = res.data;
    const arr = [];
    data.day.map(item => {
      arr.push({
        time: item.time,
        type: "美团预定人数",
        sum: item.meituan_people
      });
      arr.push({
        time: item.time,
        type: "大众/美团券人数",
        sum: item.voucher_people
      });
      arr.push({ time: item.time, type: "现金/银联人数", sum: item.payment });
      arr.push({ time: item.time, type: "免单人数", sum: item.free });
      return item;
    });
    data.day = arr;
    dispatch(changeCustomerData(res.data));
  }
};
