import React, { lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import { ConfigProvider, message } from 'antd';

import { persistor, store } from './store';
import Suspense from '@/components/Suspense';
import CheckLogin from '@/components/CheckLogin';

import zhCN from 'antd/es/locale/zh_CN';

message.config({
  top: 200,
  maxCount: 3
});

const Dashboard = Suspense(lazy(() => import('./pages/dashboard')));
const FundStatistics = Suspense(lazy(() => import('./pages/fund-statistics')));
const OperatingExpenses = Suspense(
  lazy(() => import('./pages/operating-expenses'))
);
const MarketingExpenses = Suspense(
  lazy(() => import('./pages/marketing-expenses'))
);
const StoreExpenses = Suspense(lazy(() => import('./pages/store-expenses')));
const MaintenanceCharge = Suspense(
  lazy(() => import('./pages/maintenance-charge'))
);
const ThemeTimes = Suspense(lazy(() => import('./pages/theme-times')));
const CustomerBase = Suspense(lazy(() => import('./pages/customer-base')));
const ProcurementCosts = Suspense(
  lazy(() => import('./pages/procurement-costs'))
);
const UserManage = Suspense(lazy(() => import('./pages/user-manage')));
const GoodsManage = Suspense(lazy(() => import('./pages/goods-manage')));
const ArtificialCost = Suspense(lazy(() => import('./pages/artificial-cost')));
const Staff = Suspense(lazy(() => import('./pages/staff-manage')));
const Authority = Suspense(lazy(() => import('./pages/authority')));
const Login = Suspense(lazy(() => import('./pages/login')));
const NotFindPage = Suspense(lazy(() => import('./pages/404')));
const NotAuthorizedPage = Suspense(lazy(() => import('./pages/403')));
const ThemeManage = Suspense(lazy(() => import('./pages/theme-manage')));
const WorkOvertime = Suspense(lazy(() => import('./pages/work-overtime')));
const IntegralOrder = Suspense(lazy(() => import('./pages/integral-order')));
const StoreManage = Suspense(lazy(() => import('./pages/store-manage')));
const ThemeDetail = Suspense(lazy(() => import('./pages/theme-detail')));
const SalesService = Suspense(lazy(() => import('./pages/sales-service')));

export default () => (
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <CheckLogin />
          <Switch>
            <Route path="/dashboard" exact component={Dashboard} />
            <Route path="/operating-expenses" component={OperatingExpenses} />
            <Route path="/marketing-expenses" component={MarketingExpenses} />
            <Route path="/user-manage" component={UserManage} />
            <Route path="/store-expenses" component={StoreExpenses} />
            <Route path="/maintenance-charge" component={MaintenanceCharge} />
            <Route path="/theme-times" component={ThemeTimes} />
            <Route path="/customer-base" component={CustomerBase} />
            <Route path="/procurement-costs" component={ProcurementCosts} />
            <Route path="/goods" component={GoodsManage} />
            <Route path="/store-manage" component={StoreManage} />
            <Route path="/artificial-cost" component={ArtificialCost} />
            <Route path="/authority" component={Authority} />
            <Route path="/staff" component={Staff} />
            <Route path="/theme-manage" component={ThemeManage} />
            <Route path="/theme-detail" component={ThemeDetail} />
            <Route path="/sales-service" component={SalesService} />
            <Route path="/work-overtime" component={WorkOvertime} />
            <Route path="/integral-order" component={IntegralOrder} />
            <Route path="/fund" component={FundStatistics} />
            <Route path="/login" component={Login} />
            <Route path="/404" component={NotFindPage} />
            <Route path="/403" component={NotAuthorizedPage} />
            <Redirect to="/404" />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  </ConfigProvider>
);
