import request from "@/api/request";

/**
 * 权限列表
 */
export const getAuthorityList = data => {
  return request({
    url: "/api/jurisdiction_select",
    method: "post",
    data
  });
};

/**
 * 权限详情
 */
export const getAuthorityDetail = data => {
  return request({
    url: "/api/jurisdiction_detail",
    method: "post",
    data
  });
};

/**
 * 权限修改
 */
export const updateAuthority = data => {
  return request({
    url: "/api/jurisdiction_edit",
    method: "post",
    data
  });
};

/**
 * 权限新增
 */
export const createAuthority = data => {
  return request({
    url: "/api/jurisdiction_add",
    method: "post",
    data
  });
};

/**
 * 权限删除
 */
export const deleteAuthorityItem = data => {
  return request({
    url: "/api/jurisdiction_delete",
    method: "post",
    data
  });
};
