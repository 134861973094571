import request from "@/api/request";

/**
 * 员工列表
 */
export const getStaffList = data => {
  return request({
    url: "/api/staff_select",
    method: "post",
    data
  });
};

/**
 * 员工详情
 */
export const getStaffDetail = data => {
  return request({
    url: "/api/staff_detail",
    method: "post",
    data
  });
};

/**
 * 员工修改
 */
export const updateStaff = data => {
  return request({
    url: "/api/staff_edit",
    method: "post",
    data
  });
};

/**
 * 员工新增
 */
export const createStaff = data => {
  return request({
    url: "/api/staff_add",
    method: "post",
    data
  });
};

/**
 * 员工删除
 */
export const deleteStaffItem = data => {
  return request({
    url: "/api/staff_delete",
    method: "post",
    data
  });
};
