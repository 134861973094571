import { message } from 'antd';
import moment from 'moment';
import utils from '@/utils/utils';
import {
  getThemeTime,
  updateAppointment,
  createAppointment,
  deleteAppointmentItem,
  orderConfrim,
} from '@/api/dashboard';
import {
  DETAIL_FORM,
  SHOW_EDIT_FORM,
  SHOW_GAME_DATA,
  SHOW_SETTLEMENT,
  THEME_TIME_LIST,
  SELECT_DATE,
  STORE_ID,
} from './constants';

export const changeStoreId = (val) => ({
  type: STORE_ID,
  val,
});

export const changeSelectDate = (val) => ({
  type: SELECT_DATE,
  val,
});

export const changeThemeTimeList = (val) => ({
  type: THEME_TIME_LIST,
  val,
});

export const changeShowSettlement = (val) => ({
  type: SHOW_SETTLEMENT,
  val,
});

export const changeShowEditForm = (val) => ({
  type: SHOW_EDIT_FORM,
  val,
});

export const changeShowGameData = (val) => ({
  type: SHOW_GAME_DATA,
  val,
});

export const changeDetailForm = (val) => ({
  type: DETAIL_FORM,
  val,
});

/**
 * 预约结算
 * @param {object} msg 详情信息
 */
export const handleShowSettlement = (msg) => async (dispatch) => {
  if (msg && !msg.people) return message.error('请先完成预约！');
  await dispatch(changeDetailForm(msg));
  dispatch(changeShowSettlement(msg ? true : false));
};

/**
 * 游戏详情
 * @param {object} msg 详情信息
 */
export const handleShowGameData = (msg) => async (dispatch) => {
  if (msg && !msg.people) return message.error('请先完成预约！');
  await dispatch(changeDetailForm(msg));
  dispatch(changeShowGameData(msg ? true : false));
};

/**
 * 预约人详情
 * @param {object} msg 详情信息
 */
export const handleShowEditDetail = (msg, status) => async (dispatch) => {
  await dispatch(changeDetailForm(msg));
  dispatch(changeShowEditForm(status ? status : msg ? true : false));
};

/**
 * 获取主题时间段列表
 */
export const getThemeDetailTimes = (params, info, index) => async (
  dispatch,
  getState
) => {
  const res = await getThemeTime(params);
  if (res.code === 0) {
    const { time, time_slot, reserve, type } = res.data;
    const isTime = utils.transformTime(time).split(' ')[0];
    const { themeTimeList, selectDate } = getState().dashboard;
    if (!params.time) {
      !selectDate && dispatch(changeSelectDate(moment(isTime, 'YYYY-MM-DD')));
    }
    let list;
    if (info.type == 2) {
      const arr = [];
      reserve.map((item, index) =>
        arr.push({
          id: time_slot.length,
          id1: time_slot.length,
          id2: time_slot.length,
          index,
          people: item,
          start_time: item.time_start,
          end_time: item.time_end,
          store_id: item.store_id,
          theme_id: item.theme_id,
          type: info.type,
          user_id: item.user_id,
        })
      );
      list = arr;
    } else {
      for (let k = 0; k < time_slot.length; k++) {
        const index = reserve.findIndex(
          (item) => item.time_id === time_slot[k].id
        );
        time_slot[k].index = k;
        if (index !== -1) {
          time_slot[k].people = reserve[index];
          continue;
        }
      }
      list = time_slot;
    }
    themeTimeList[index] = {
      title: info.theme,
      list,
      type: info.type,
      theme_id: info.theme_id,
      store_id: info.store_id,
      index,
      weekendType: type
    };
    dispatch(changeThemeTimeList(themeTimeList));
  }
};

/**
 * 修改预约
 */
export const editAppointment = (params, isGameData) => (dispatch) =>
  new Promise(async (resolve) => {
    console.log(params.workno,'workno')
    if (!params.is_integral) delete params.is_integral;
    if (!params.birthday_people) delete params.birthday_people;
    if (!params.free) delete params.free;
    if (!params.workno) delete params.workno;
    if (!params.job_name) delete params.job_name;
    if (!params.people_1) delete params.people_1;
    if (!params.remarks) delete params.remarks;
    delete params.update_time;
    const res = await updateAppointment(params);
    if (res.code === 0) {
      message.success(isGameData || '预约修改成功');
      return resolve(true);
    }
    resolve(false);
    message.error(res.msg);
  });

/**
 * 删除预约
 */
export const deleteAppointment = (id) => (dispatch) =>
  new Promise(async (resolve) => {
    const res = await deleteAppointmentItem({ id });
    if (res.code === 0) {
      message.success('删除预约成功');
      return resolve(true);
    }
    resolve(false);
    message.error(res.msg);
  });

/**
 * 新增预约
 */
export const addAppointment = (params) => (dispatch) =>
  new Promise(async (resolve) => {
    if (!params.age) delete params.age;
    if (!params.source) delete params.source;
    if (!params.man_woman) delete params.man_woman;
    if (!params.job_name) delete params.job_name;
    if (!params.remarks) delete params.remarks;
    const res = await createAppointment(params);
    if (res.code === 0) {
      message.success('预约成功');
      dispatch(handleShowEditDetail(null));
      return resolve(true);
    }
    resolve(false);
    message.error(res.msg);
  });

/**
 * 核销订单
 */
export const onOrderConfrim = (params) =>
  new Promise(async (resolve) => {
    const res = await orderConfrim(params);
    if (res.code === 0) {
      message.success('核销成功');
      return resolve(true);
    }
    resolve(false);
    message.error(res.msg);
  });
