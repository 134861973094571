import utils from "@/utils/utils";
import {
  STOREID,
  SETTLEMENT_DATE,
  SETTLEMENT_LIST,
  LINE_CARD_MSG,
  THEME_STATISTICS,
  STORE_STATISTICS,
  STORE_SELECTED_DATE,
  THEME_SELECTED_DATE
} from "./constants";

const date = utils.getMonthStartAndEnd();

export const INITIAL_STATE = {
  storeId: undefined,
  lineCardMsg: null, // 各种类型金额（开票，其他，现金，银联，美团，大众/美团）
  settlementDate: date, // 收银数据统计时间
  settlementList: [], //收银数据统计
  themeSelectedDate: date, // 主题营业额选择日期
  themeStatistics: [], // 主题营业额统计
  storeSelectedDate: date, // 门店营业额选择日期
  storeStatistics: [] // 门店营业额统计
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STOREID:
      return {
        ...state,
        storeId: action.val
      };
    case STORE_SELECTED_DATE:
      return {
        ...state,
        storeSelectedDate: action.val && [...action.val]
      };
    case STORE_STATISTICS:
      return {
        ...state,
        storeStatistics: action.val && [...action.val]
      };
    case THEME_SELECTED_DATE:
      return {
        ...state,
        themeSelectedDate: action.val && [...action.val]
      };
    case THEME_STATISTICS:
      return {
        ...state,
        themeStatistics: action.val && [...action.val]
      };
    case SETTLEMENT_DATE:
      return {
        ...state,
        settlementDate: action.val && [...action.val]
      };
    case SETTLEMENT_LIST:
      return {
        ...state,
        settlementList: action.val && [...action.val]
      };
    case LINE_CARD_MSG:
      return {
        ...state,
        lineCardMsg: action.val && { ...action.val }
      };
    default:
      return state;
  }
};
