import request from "@/api/request";

/**
 * 主题列表
 */
export const getThemeList = data => {
  return request({
    url: "/api/theme_select",
    method: "post",
    data
  });
};

/**
 * 主题详情
 */
export const getThemeDetail = data => {
  return request({
    url: "/api/theme_detail",
    method: "post",
    data
  });
};

/**
 * 主题修改
 */
export const updateTheme = data => {
  return request({
    url: "/api/theme_edit",
    method: "post",
    data
  });
};

/**
 * 主题新增
 */
export const createTheme = data => {
  return request({
    url: "/api/theme_add",
    method: "post",
    data
  });
};

/**
 * 主题删除
 */
export const deleteThemeItem = data => {
  return request({
    url: "/api/theme_delete",
    method: "post",
    data
  });
};
