export const SELECTED_ROW_KEYS = "AUTHORITY/SELECTED_ROW_KEYS";
export const SEARCH_PARAMS = "AUTHORITY/SEARCH_PARAMS";
export const SEARCH_CONDITION = "AUTHORITY/SEARCH_CONDITION";
export const LIST = "AUTHORITY/LIST";
export const SHOW_EDIT = "AUTHORITY/SHOW_EDIT";
export const DETAIL_FORM = "AUTHORITY/DETAIL_FORM";
export const ALL_MENU_LIST = "AUTHORITY/ALL_MENU_LIST";
export const PAGE = "AUTHORITY/PAGE";
export const ROWS = "AUTHORITY/ROWS";
export const FREE_SETTING = "AUTHORITY/FREE_SETTING";
export const AUTHORITY_SELECTOR = "AUTHORITY/AUTHORITY_SELECTOR";
