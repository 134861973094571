module.exports =
  // process.env.NODE_ENV === 'development'
  //   ? {
  //       base_url: 'http://192.168.1.27:10'
  //     }
  //   : 
    {
        // base_url: 'http://mi.meooh.com'

        base_url: 'http://mi.shenmixiaozhen.com'
      }
