import request from "@/api/request";

/**
 * 密室维护费用列表
 */
export const getMaintenanceChargeList = data => {
  return request({
    url: "/api/maintenance_select",
    method: "post",
    data
  });
};

/**
 * 密室维护费用详情
 */
export const getMaintenanceChargeDetail = data => {
  return request({
    url: "/api/maintenance_detail",
    method: "post",
    data
  });
};

/**
 * 密室维护费用修改
 */
export const updateMaintenanceCharge = data => {
  return request({
    url: "/api/maintenance_edit",
    method: "post",
    data
  });
};

/**
 * 密室维护费用新增
 */
export const createMaintenanceCharge = data => {
  return request({
    url: "/api/maintenance_add",
    method: "post",
    data
  });
};

/**
 * 密室维护费用删除
 */
export const deleteMaintenanceChargeItem = data => {
  return request({
    url: "/api/maintenance_delete",
    method: "post",
    data
  });
};
