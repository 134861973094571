import { COLLAPSED, LAYOUT_MENU, STORE_LIST, ALL_THEME } from './constants'
import { userMenu, getAllStore } from '@/api'
import { getTheme } from '@/api/dashboard'

export const changeCollapsed = val => ({
  type: COLLAPSED,
  val
})

export const changeLayoutMenu = val => ({
  type: LAYOUT_MENU,
  val
})

export const changeStoreList = val => ({
  type: STORE_LIST,
  val
})

export const changeAllTheme = val => ({
  type: ALL_THEME,
  val
})

export const getLayoutMenu = () => async dispatch => {
  try {
    const res = await userMenu()
    if (res.code === 0) {
      const data = res.data.map(item => {
        item.id = +item.id
        item.type = +item.type
        return item
      })
      const children = data.filter(item => item.type !== 0)
      const arr = data.filter(item => item.type === 0)
      children.map(item => {
        const index = arr.findIndex(it => it.id === item.type)
        if (index) {
          if (arr[index] && !arr[index].hasOwnProperty('children'))
            arr[index].children = []
          if (index !== -1) arr[index].children.push(item)
        }
        return item
      })
      dispatch(changeLayoutMenu(arr))
    }
  } catch (error) {}
}

/**
 * 获取全部门店列表
 */
export const getAllStoreData = params => async dispatch => {
  const res = await getAllStore(params)
  if (res.code === 0) {
    dispatch(changeStoreList(res.data))
  }
}

/**
 * 获取全部主题列表
 */
export const getAllThemeData = id => async dispatch => {
  const res = await getTheme(id ? { store_id: id } : undefined)
  if (res.code === 0) {
    const arr = {}
    const data = res.data
    for (const key in data) {
      if (!arr.hasOwnProperty(data[key].store_id)) {
        arr[data[key].store_id] = []
      }
      arr[data[key].store_id].push(data[key])
    }
    dispatch(
      changeAllTheme({
        list: data,
        is: arr
      })
    )
  }
}
