import request from "./request";

/**
 * 获取主题列表
 */
export const getTheme = data => {
  return request({
    url: "/api/theme",
    method: "post",
    data: data || undefined
  });
};

/**
 * 获取对应主题时间表以及预约人信息
 * @param {string} time 时间（选传）传时间戳字符串
 */
export const getThemeTime = data => {
  return request({
    url: "/api/time_slot",
    method: "post",
    data
  });
};

/**
 * 周末开场
 * @param {string} time 时间（选传）传时间戳字符串
 */
export const getThemeWeekend = data => {
  return request({
    url: "/api/theme_weekend",
    method: "post",
    data
  });
};

/**
 * 订单核销
 */
export const orderConfrim = data => {
  return request({
    url: "/api/write",
    method: "post",
    data
  });
};

/**
 * 预约修改
 */
export const updateAppointment = data => {
  return request({
    url: "/api/reserve_edit",
    method: "post",
    data
  });
};

/**
 * 预约新增
 */
export const createAppointment = data => {
  return request({
    url: "/api/reserve_add",
    method: "post",
    data
  });
};

/**
 * 预约删除
 */
export const deleteAppointmentItem = data => {
  return request({
    url: "/api/reserve_delete",
    method: "post",
    data
  });
};
