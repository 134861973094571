import request from "@/api/request";

/**
 * 营业费用列表
 */
export const getOperatingExpensesList = data => {
  return request({
    url: "/api/expenditure_select",
    method: "post",
    data
  });
};

/**
 * 营业费用详情
 */
export const getOperatingExpensesDetail = data => {
  return request({
    url: "/api/expenditure_detail",
    method: "post",
    data
  });
};

/**
 * 营业费用修改
 */
export const updateOperatingExpenses = data => {
  return request({
    url: "/api/expenditure_edit",
    method: "post",
    data
  });
};

/**
 * 营业费用新增
 */
export const createOperatingExpenses = data => {
  return request({
    url: "/api/expenditure_add",
    method: "post",
    data
  });
};

/**
 * 营业费用删除
 */
export const deleteOperatingExpensesItem = data => {
  return request({
    url: "/api/expenditure_delete",
    method: "post",
    data
  });
};
