import { message } from "antd";
import moment from "moment";
import utils from "@/utils/utils";
import * as api from "@/api/themeTimes";
import {
  SEARCH_PARAMS,
  SEARCH_CONDITION,
  LIST,
  SELECTED_ROW_KEYS,
  SHOW_EDIT,
  DETAIL_FORM,
  PAGE,
  ROWS,
  FREE_SETTING
} from "./constants";

/**
 * 自由设置多个参数
 * @param {*} obj 对象键值对
 */
export const handleFreeSetting = obj => ({
  type: FREE_SETTING,
  obj
});

export const changePage = val => ({
  type: PAGE,
  val
});

export const changePageSize = val => ({
  type: ROWS,
  val
});

export const changeSelectedRowKeys = val => ({
  type: SELECTED_ROW_KEYS,
  val
});

export const changeSearchParams = val => ({
  type: SEARCH_PARAMS,
  val
});

export const changeSearchCondition = val => ({
  type: SEARCH_CONDITION,
  val
});

export const changeShowEdit = val => ({
  type: SHOW_EDIT,
  val
});

export const changeDetailForm = val => ({
  type: DETAIL_FORM,
  val
});

export const changeList = (list, total) => ({
  type: LIST,
  list,
  total
});

/**
 * 获取列表
 */
export const getList = params => async (dispatch, getState) => {
  try {
    const res = await api.getThemeTimesList(params);
    if (res.code === 0) {
      const { data, total } = res.data;
      data.map(item => {
        item.time = utils.transformTime(item.carte_time);
        item.month = item.add_time
          ? moment(item.add_time * 1000).format("YYYY/MM/DD")
          : "";
        item.key = item.id;
        return item;
      });
      dispatch(changeList(data, total));
    }
  } catch (error) {}
};

/**
 * 获取列表
 */
export const getLineDetail = id => (dispatch, getState) =>
  new Promise(async resolve => {
    try {
      const res = await api.getThemeTimesDetail({
        id
      });
      if (res.code === 0) {
        res.data.add_time = moment(res.data.add_time * 1000).format(
          "YYYY/MM/DD"
        );
        await dispatch(changeDetailForm(res.data));
      }
    } catch (error) {}
    resolve();
  });

/**
 * 更新详情信息
 */
export const updateEditForm = params =>
  new Promise(async resolve => {
    const res = await api.updateThemeTimes(params);
    if (res.code === 0) {
      message.success("修改成功");
      return resolve(true);
    }
    message.error(res.msg);
    resolve(false);
  });

/**
 * 创建详情信息
 */
export const createEditForm = params =>
  new Promise(async resolve => {
    const res = await api.createThemeTimes(params);
    if (res.code === 0) {
      message.success("创建成功");
      return resolve(true);
    }
    message.error(res.msg);
    resolve(false);
  });

/**
 * 删除事件
 * @param {array} ids ID数组
 */
export const handleDeleteLine = ids =>
  new Promise(async resolve => {
    const res = await api.deleteThemeTimesItem({ id: ids.join(",") });
    if (res.code === 0) {
      message.success("删除成功");
      return resolve(true);
    }
    message.success(res.msg);
    resolve(false);
  });
