import request from "@/api/request";

/**
 * 主题时间段列表
 */
export const getThemeTimesList = data => {
  return request({
    url: "/api/slot_select",
    method: "post",
    data
  });
};

/**
 * 主题时间段详情
 */
export const getThemeTimesDetail = data => {
  return request({
    url: "/api/slot_detail",
    method: "post",
    data
  });
};

/**
 * 主题时间段修改
 */
export const updateThemeTimes = data => {
  return request({
    url: "/api/slot_edit",
    method: "post",
    data
  });
};

/**
 * 主题时间段新增
 */
export const createThemeTimes = data => {
  return request({
    url: "/api/slot_add",
    method: "post",
    data
  });
};

/**
 * 主题时间段删除
 */
export const deleteThemeTimesItem = data => {
  return request({
    url: "/api/slot_delete",
    method: "post",
    data
  });
};
