import { USER_MESSAGE, LOADING, INPUT_FORM } from "./constants";

export const INITIAL_STATE = {
  loading: false,
  inputForm: {
    username: "",
    password: ""
  },
  userMessage: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.val
      };
    case INPUT_FORM:
      return {
        ...state,
        inputForm: action.val && { ...action.val }
      };
    case USER_MESSAGE:
      return {
        ...state,
        userMessage: action.val && { ...action.val }
      };
    default:
      return state;
  }
};
