import request from "@/api/request";

/**
 * 公共/密室用品列表
 */
export const getGoodsManageList = data => {
  return request({
    url: "/api/good_select",
    method: "post",
    data
  });
};

/**
 * 公共/密室用品详情
 */
export const getGoodsManageDetail = data => {
  return request({
    url: "/api/good_detail",
    method: "post",
    data
  });
};

/**
 * 公共/密室用品修改
 */
export const updateGoodsManage = data => {
  return request({
    url: "/api/good_edit",
    method: "post",
    data
  });
};

/**
 * 公共/密室用品新增
 */
export const createGoodsManage = data => {
  return request({
    url: "/api/good_add",
    method: "post",
    data
  });
};

/**
 * 公共/密室用品删除
 */
export const deleteGoodsManageItem = data => {
  return request({
    url: "/api/good_delete",
    method: "post",
    data
  });
};
