import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import { reducer as layoutReducer } from '../layout/store';
import { reducer as loginReducer } from '../pages/login/store';
import { reducer as dashboardReducer } from '../pages/dashboard/store';
import { reducer as fundStatisticsReducer } from '../pages/fund-statistics/store';
import { reducer as staffReducer } from '../pages/staff-manage/store';
import { reducer as procurementCostsReducer } from '../pages/procurement-costs/store';
import { reducer as authorityReducer } from '../pages/authority/store';
import { reducer as userReducer } from '../pages/user-manage/store';
import { reducer as goodsManageReducer } from '../pages/goods-manage/store';
import { reducer as themeTimesReducer } from '../pages/theme-times/store';
import { reducer as maintenanceChargeReducer } from '../pages/maintenance-charge/store';
import { reducer as operatingExpensesReducer } from '../pages/operating-expenses/store';
import { reducer as marketingExpensesReducer } from '../pages/marketing-expenses/store';
import { reducer as storeExpensesReducer } from '../pages/store-expenses/store';
import { reducer as customerBaseReducer } from '../pages/customer-base/store';
import { reducer as artificialCostReducer } from '../pages/artificial-cost/store';
import { reducer as themeManageReducer } from '../pages/theme-manage/store';
import { reducer as workOvertimeReducer } from '../pages/work-overtime/store';
import { reducer as integralOrderReducer } from '../pages/integral-order/store';
import { reducer as themeDetailReducer } from '../pages/theme-detail/store';
import { reducer as SalesServiceReducer } from '../pages/sales-service/store';

const config = {
  key: 'root',
  storage,
};

const reducer = persistCombineReducers(config, {
  layout: layoutReducer,
  login: loginReducer,
  user: userReducer,
  customerBase: customerBaseReducer,
  authority: authorityReducer,
  integralOrder: integralOrderReducer,
  storeExpenses: storeExpensesReducer,
  procurementCosts: procurementCostsReducer,
  goodsManage: goodsManageReducer,
  themeManage: themeManageReducer,
  themeTimes: themeTimesReducer,
  dashboard: dashboardReducer,
  maintenanceCharge: maintenanceChargeReducer,
  workOvertime: workOvertimeReducer,
  staff: staffReducer,
  fundStatistics: fundStatisticsReducer,
  operatingExpenses: operatingExpensesReducer,
  marketingExpenses: marketingExpensesReducer,
  artificialCost: artificialCostReducer,
  themeDetail: themeDetailReducer,
  SalesService: SalesServiceReducer,
});

export default reducer;
