export const SELECTED_ROW_KEYS = "INTEGRAL_ORDER/SELECTED_ROW_KEYS";
export const SEARCH_PARAMS = "INTEGRAL_ORDER/SEARCH_PARAMS";
export const SEARCH_CONDITION = "INTEGRAL_ORDER/SEARCH_CONDITION";
export const LIST = "INTEGRAL_ORDER/LIST";
export const SHOW_EDIT = "INTEGRAL_ORDER/SHOW_EDIT";
export const DETAIL_FORM = "INTEGRAL_ORDER/DETAIL_FORM";
export const ALL_GOODS_LIST = "INTEGRAL_ORDER/ALL_GOODS_LIST";
export const PAGE = "INTEGRAL_ORDER/PAGE";
export const ROWS = "INTEGRAL_ORDER/ROWS";
export const FREE_SETTING = "INTEGRAL_ORDER/FREE_SETTING";
