import {
  CUSTOMER_DATA,
  DATE_RANGE,
  SEARCH_CONDITION,
  THEME_ID,
  STORE_ID
} from "./constants";

export const INITIAL_STATE = {
  customerData: null,
  dateRange: undefined,
  themeId: undefined,
  searchCondition: null,
  storeId:undefined
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORE_ID:
      return {
        ...state,
        storeId: action.val
      };
    case THEME_ID:
      return {
        ...state,
        themeId: action.val
      };
    case CUSTOMER_DATA:
      return {
        ...state,
        customerData: action.val && { ...action.val }
      };
    case DATE_RANGE:
      return {
        ...state,
        dateRange: action.val && [...action.val]
      };
    case SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: action.val && { ...action.val }
      };
    default:
      return state;
  }
};
