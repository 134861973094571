import * as api from '@/api/workOvetTime';
import * as authorityApi from '@/api/authority';
import utils from '@/utils/utils';
import moment from 'moment';
import {
  SEARCH_PARAMS,
  SEARCH_CONDITION,
  LIST,
  SELECTED_ROW_KEYS,
  SHOW_EDIT,
  DETAIL_FORM,
  ALL_AUTHORITY_LIST,
  PAGE,
  ROWS,
  FREE_SETTING,
  DETAIL_PAGE,
  DETAIL_ROWS,
  DETAIL_SEARCH_CONDITION,
  DETAIL_SEARCH_PARAMS,
  DETAIL_VALUE,
} from './constants';

/**
 * 自由设置多个参数
 * @param {*} obj 对象键值对
 */
export const handleFreeSetting = (obj) => ({
  type: FREE_SETTING,
  obj,
});

export const changeDetailPage = (val) => ({
  type: DETAIL_PAGE,
  val,
});

export const changeDetailPageSize = (val) => ({
  type: DETAIL_ROWS,
  val,
});

export const changeDetailSearchParams = (val) => ({
  type: DETAIL_SEARCH_PARAMS,
  val,
});

export const changeDetailSearchCondition = (val) => ({
  type: DETAIL_SEARCH_CONDITION,
  val,
});

export const changeDetail = (list, total) => ({
  type: DETAIL_VALUE,
  list,
  total,
});

export const changePage = (val) => ({
  type: PAGE,
  val,
});

export const changePageSize = (val) => ({
  type: ROWS,
  val,
});

export const changeSelectedRowKeys = (val) => ({
  type: SELECTED_ROW_KEYS,
  val,
});

export const changeSearchParams = (val) => ({
  type: SEARCH_PARAMS,
  val,
});

export const changeSearchCondition = (val) => ({
  type: SEARCH_CONDITION,
  val,
});

export const changeShowEdit = (val) => ({
  type: SHOW_EDIT,
  val,
});

export const changeDetailForm = (val) => ({
  type: DETAIL_FORM,
  val,
});

export const changeAuthorityList = (val) => ({
  type: ALL_AUTHORITY_LIST,
  val,
});

export const changeList = (list, total) => ({
  type: LIST,
  list,
  total,
});

/**
 * 获取列表
 */
export const getList = (params) => async (dispatch) => {
  try {
    const res = await api.getOverWorkList(params);
    if (res.code === 0) {
      dispatch(changeList(res.data, res.data.length));
    }
  } catch (error) {}
};

/**
 * 获取列表
 */
export const getAllAuthority = () => (dispatch) =>
  new Promise(async (resolve) => {
    const res = await authorityApi.getAuthorityList({
      type: 1,
    });
    if (res.code === 0) {
      await dispatch(changeAuthorityList(res.data));
    }
    resolve();
  });

/**
 * 获取详情
 */
export const getLineDetail = (msg) => (dispatch, getState) =>
  new Promise(async (resolve) => {
    try {
      dispatch(changeDetailForm(msg));
      const {
        detailPage,
        detailRows,
        searchCondition,
      } = getState().workOvertime;
      const params = {
        page: detailPage,
        rows: detailRows,
        staff_id: msg.staff_id,
      };
      if (searchCondition.add_time && searchCondition.add_time.length) {
        const date = searchCondition.add_time;
        params.time = moment(date[0], 'YYYY-MM-DD').unix();
        params.time_end =
          new Date(
            `${moment(date[1]).format('YYYY-MM-DD')} 23:59:59`
          ).getTime() / 1000;
      }
      const res = await api.getOverWorkDetail(params);
      if (res.code === 0) {
        const { total, data } = res.data;
        data.map((item) => {
          item.time = utils.transformTime(item.carte_time);
          return item;
        });
        dispatch(changeDetail({ list: data, total }));
      }
    } catch (error) {}
    resolve();
  });
