export function transformTime(timestamp = null, noTime) {
  function addZero(m) {
    return m < 10 ? "0" + m : m;
  }
  if (timestamp) {
    var time = new Date(
      `${timestamp}`.length < 13 ? timestamp * 1000 : +timestamp
    );
    var y = time.getFullYear();
    var M = time.getMonth() + 1;
    var d = time.getDate();
    var h = time.getHours();
    var m = time.getMinutes();
    var s = time.getSeconds();
    if (noTime) return y + "-" + addZero(M) + "-" + addZero(d);
    return (
      y +
      "-" +
      addZero(M) +
      "-" +
      addZero(d) +
      " " +
      addZero(h) +
      ":" +
      addZero(m) +
      ":" +
      addZero(s)
    );
  } else {
    return "";
  }
}

/**
 * 计算两个时间戳的差
 * @param {number} begin_time 开始时间
 * @param {number} end_time 结束时间
 */
export function timediff(begin_time, end_time) {
  //计算天数
  const time = end_time - begin_time;
  const days = Math.floor(time / (24 * 3600 * 1000));
  //计算小时数
  const remain = time % (24 * 3600 * 1000);
  const hours = Math.floor(remain / (3600 * 1000));
  //计算分钟数
  const remain_2 = remain % (3600 * 1000);
  const mins = Math.floor(remain_2 / (60 * 1000));
  //计算秒数
  const secs = remain_2 % 60;
  return {
    day: days,
    hour: hours,
    minutes: mins,
    seconds: secs
  };
}

// 防抖
export function debounce(fn, interval) {
  var timer;
  var gapTime = interval || 1000; //间隔时间，如果interval不传，则默认1000ms
  var msg = null;
  return function(e) {
    clearTimeout(timer);
    var context = this;
    msg = e;
    timer = setTimeout(function() {
      fn.call(context, msg);
    }, gapTime);
  };
}

// 验证手机号码;
export function checkPhone(value) {
  if (!/^1[3456789]\d{9}$/.test(value)) return true;
  return false;
}

export function getWeekStartAndEnd() {
  const s = new Date(),
    s_day = s.getDay(),
    s_date = s.getDate();
  const d = new Date(),
    d_day = d.getDay(),
    d_date = d.getDate();

  if (d_day === 0) d.setDate(d_date - 6);
  else d.setDate(d_date - d_day + 1);

  if (s_day !== 0) s.setDate(s_date + (7 - s_day));
  return [d, s];
}

export function getMonthStartAndEnd() {
  const s = new Date();
  const d = new Date(s.getFullYear(), s.getMonth() + 1, 0);
  s.setDate(1);
  return [s, d];
}

export function getYearStartAndEnd() {
  const s = new Date();
  var yearStartDate = new Date(s.getFullYear(), 0, 1);
  var yearEndDate = new Date(s.getFullYear(), 11, 31);
  return [yearStartDate, yearEndDate];
}

export const ageList = [
  { id: 1, title: "小学生" },
  { id: 2, title: "初高中" },
  { id: 3, title: "中青年" },
  { id: 4, title: "游客" },
  { id: 5, title: "亲子" },
  { id: 6, title: "忙着赚钱" }
];
export const businessList = [
  { id: 1, title: "美团大众" },
  { id: 2, title: "现场购票" },
  { id: 3, title: "抖音" },
  { id: 4, title: "忙着赚钱" },
  { id: 5, title: "关系户" },
  { id: 6, title: "特殊活动" }
];

const operatingTypes = [
  { id: 1, title: "房租" },
  { id: 2, title: "物业" },
  { id: 3, title: "水电" },
  { id: 4, title: "平台费用" }
];

const staffIdentity = [
  { id: 1, title: "管理" },
  { id: 2, title: "店长" },
  { id: 3, title: "员工" },
]
export const payTypeList = [
  { id: 1, title: "现金" },
  { id: 2, title: "银联码" },
  { id: 3, title: "美团劵" },
]

export const isIntegralList = [
  { id: 1, title: "记录" },
  { id: 2, title: "不记录" }
]

export const isPhoneList = [
  { id: '1', title: "是" },
  { id: '0', title: "否" }
]

export const refundType = [
  { id: '1', title: '美团卷'},
  { id: '2', title: '现金'}
]

export const numberList = [
  { id: 0, title: '0'},
  { id: 1, title: '1'},
  { id: 2, title: '2'},
  { id: 3, title: '3'},
  { id: 4, title: '4'},
  { id: 5, title: '5'},
  { id: 6, title: '6'},
  { id: 7, title: '7'},
  { id: 8, title: '8'},
  { id: 9, title: '9'},
]

export const clientTypeList = [
  { id: 1, title: '小白'},
  { id: 2, title: '老玩家'},
  { id: 3, title: '回头客'},
  { id: 4, title: '忙着赚钱'},
]

export default {
  getWeekStartAndEnd,
  getMonthStartAndEnd,
  getYearStartAndEnd,
  operatingTypes,
  staffIdentity,
  timediff,
  checkPhone,
  transformTime,
  debounce,
  numberList,
  clientTypeList
};
