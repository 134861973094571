import { message } from "antd";
import { USER_MESSAGE, LOADING, INPUT_FORM } from "./constants";
import { actions as layoutActions } from "@/layout/store";
import { INITIAL_STATE } from "./reducer";
import { userLogin } from "../../../api";

export const changeLoading = val => ({
  type: LOADING,
  val
});

export const changeUserMessage = val => ({
  type: USER_MESSAGE,
  val
});

export const changeInputForm = val => ({
  type: INPUT_FORM,
  val
});

/**
 * 登录
 */
export const handleUserLogin = params => async dispatch => {
  dispatch(changeLoading(true));
  try {
    const res = await userLogin({
      mobile: params.username,
      password: params.password
    });
    if (res.code === 0) {
      message.success("登录成功");
      res.data.power = res.data.power.split(",").map(item => +item);
      dispatch(changeUserMessage(res.data));
    } else message.error(res.msg);
  } catch (error) {
    message.error(error.msg);
  }
  dispatch(changeLoading(false));
};

/**
 * 退出登录
 */
export const handleUserLogout = () =>async dispatch => {
  // 布局
  dispatch(layoutActions.changeCollapsed(false));
  dispatch(layoutActions.changeLayoutMenu([]));
 await dispatch(layoutActions.changeStoreList(null));
  dispatch(layoutActions.changeAllTheme([]));
  // /布局

  dispatch(changeInputForm(INITIAL_STATE.inputForm));
  dispatch(changeLoading(false));
  dispatch(changeUserMessage(null));
};
