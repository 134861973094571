import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

function CheckLogin(props) {
  const { location, history } = props;

  const { layoutMenu, userMessage } = useSelector(state => ({
    layoutMenu: state.layout.layoutMenu,
    ...state.login
  }));

  useEffect(() => {
    if (!userMessage && location.pathname.indexOf("login") === -1)
      history.replace("/login");
    else if (userMessage && location.pathname.indexOf("login") !== -1)
      history.replace("/dashboard");

    if (userMessage && userMessage.position !== 1) {
      const nowPath = layoutMenu.find(
        item => `/${item.path}` === location.pathname
      );
      if (nowPath) {
        const is = userMessage.power.indexOf(nowPath.id);
        if (is === -1) history.push("/403");
      }
    }
  }, [history, location, layoutMenu, userMessage]);

  if (!userMessage) {
    return <Redirect to="/login" />;
  }

  return null;
}

export default withRouter(CheckLogin);
