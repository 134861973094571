export const SELECTED_ROW_KEYS = "WORKOVERTIME/SELECTED_ROW_KEYS";
export const SEARCH_PARAMS = "WORKOVERTIME/SEARCH_PARAMS";
export const SEARCH_CONDITION = "WORKOVERTIME/SEARCH_CONDITION";
export const LIST = "WORKOVERTIME/LIST";
export const SHOW_EDIT = "WORKOVERTIME/SHOW_EDIT";
export const DETAIL_FORM = "WORKOVERTIME/DETAIL_FORM";
export const ALL_AUTHORITY_LIST = "WORKOVERTIME/ALL_AUTHORITY_LIST";
export const PAGE = "WORKOVERTIME/PAGE";
export const ROWS = "WORKOVERTIME/ROWS";
export const FREE_SETTING = "WORKOVERTIME/FREE_SETTING";
export const DETAIL_SEARCH_CONDITION = "WORKOVERTIME/DETAIL_SEARCH_CONDITION";
export const DETAIL_SEARCH_PARAMS = "WORKOVERTIME/DETAIL_SEARCH_PARAMS";
export const DETAIL_PAGE = "WORKOVERTIME/DETAIL_PAGE";
export const DETAIL_ROWS = "WORKOVERTIME/DETAIL_ROWS";
export const DETAIL_VALUE = "WORKOVERTIME/DETAIL_VALUE";
