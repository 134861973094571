import request from "@/api/request";

/**
 * 加班人员列表
 */
export const getOverWorkList = data => {
  return request({
    url: "/api/over_list",
    method: "post",
    data
  });
};

/**
 * 加班人员详情
 */
export const getOverWorkDetail = data => {
  return request({
    url: "/api/over_work",
    method: "post",
    data
  });
};