import moment from "moment";
import utils from "@/utils/utils";
import * as api from "@/api/themeDetail";
import {
  SEARCH_PARAMS,
  SEARCH_CONDITION,
  LIST,
  SELECTED_ROW_KEYS,
  SHOW_EDIT,
  DETAIL_FORM,
  PAGE,
  ROWS,
  FREE_SETTING,
  UPDATE_ID
} from "./constants";

/**
 * 自由设置多个参数
 * @param {*} obj 对象键值对
 */
export const handleFreeSetting = obj => ({
  type: FREE_SETTING,
  obj
});

export const changePage = val => ({
  type: PAGE,
  val
});

export const changePageSize = val => ({
  type: ROWS,
  val
});

export const changeSelectedRowKeys = val => ({
  type: SELECTED_ROW_KEYS,
  val
});

export const changeSearchParams = val => ({
  type: SEARCH_PARAMS,
  val
});

export const changeSearchCondition = val => ({
  type: SEARCH_CONDITION,
  val
});

export const changeShowEdit = val => ({
  type: SHOW_EDIT,
  val
});

export const changeupDateId = val => ({
  type: UPDATE_ID,
  val
})

export const changeDetailForm = val => ({
  type: DETAIL_FORM,
  val
});

export const changeList = (list, total) => ({
  type: LIST,
  list,
  total
});

/**
 * 获取列表
 */
export const getList = params => async (dispatch, getState) => {
  try {
    const res = await api.getThemeDetailList(params);
    if (res.code === 0) {
      const { data, total } = res.data;
      data.map(item => {
        item.time = utils.transformTime(item.carte_time);
        item.carte_time = item.carte_time
          ? moment(item.carte_time * 1000).format("YYYY/MM/DD")
          : "";
        item.key = item.id;
        return item;
      });
      dispatch(changeList(data, total));
      
    }
  } catch (error) {}
};

/**
 * 修改
 */
 export const updateList = params => async (dispatch, getState) => {
  try {
    const res = await api.updateTheme(params);
    if (res.code === 0) {
      const { data, total } = res.data;
      console.log(data)
      // data.map(item => {
      //   item.time = utils.transformTime(item.carte_time);
      //   item.carte_time = item.carte_time
      //     ? moment(item.carte_time * 1000).format("YYYY/MM/DD")
      //     : "";
      //   item.key = item.id;
      //   return item;
      // });
      dispatch(changeList(data, total));
    }
  } catch (error) {}
};