export const SELECTED_ROW_KEYS = "ARTIFICIAL_COST/SELECTED_ROW_KEYS";
export const SEARCH_PARAMS = "ARTIFICIAL_COST/SEARCH_PARAMS";
export const SEARCH_CONDITION = "ARTIFICIAL_COST/SEARCH_CONDITION";
export const LIST = "ARTIFICIAL_COST/LIST";
export const SHOW_EDIT = "ARTIFICIAL_COST/SHOW_EDIT";
export const DETAIL_FORM = "ARTIFICIAL_COST/DETAIL_FORM";
export const ALL_STAFF_LIST = "ARTIFICIAL_COST/ALL_STAFF_LIST";
export const PAGE = "ARTIFICIAL_COST/PAGE";
export const ROWS = "ARTIFICIAL_COST/ROWS";
export const FREE_SETTING = "ARTIFICIAL_COST/FREE_SETTING";
