import { message } from 'antd';
import * as api from '@/api';
import { orderConfrim } from '@/api/dashboard';
import utils from '@/utils/utils';
// import * as goodsManageApi from '@/api/goodsManage';
import {
  SEARCH_PARAMS,
  SEARCH_CONDITION,
  LIST,
  SELECTED_ROW_KEYS,
  SHOW_EDIT,
  DETAIL_FORM,
  ALL_GOODS_LIST,
  PAGE,
  ROWS,
  FREE_SETTING
} from './constants';

/**
 * 自由设置多个参数
 * @param {*} obj 对象键值对
 */
export const handleFreeSetting = obj => ({
  type: FREE_SETTING,
  obj
});

export const changePage = val => ({
  type: PAGE,
  val
});

export const changePageSize = val => ({
  type: ROWS,
  val
});

export const changeSelectedRowKeys = val => ({
  type: SELECTED_ROW_KEYS,
  val
});

export const changeSearchParams = val => ({
  type: SEARCH_PARAMS,
  val
});

export const changeSearchCondition = val => ({
  type: SEARCH_CONDITION,
  val
});

export const changeShowEdit = val => ({
  type: SHOW_EDIT,
  val
});

export const changeDetailForm = val => ({
  type: DETAIL_FORM,
  val
});

export const changeGoodsList = val => ({
  type: ALL_GOODS_LIST,
  val
});

export const changeList = (list, total) => ({
  type: LIST,
  list,
  total
});

/**
 * 获取列表
 */
export const getList = params => async dispatch => {
  try {
    const res = await api.getIntegralOrder(params);
    if (res.code === 0) {
      const { data, total } = res.data;
      data.map(item => {
        item.key = item.id;
        item.time = utils.transformTime(item.createtime);
        return item;
      });
      dispatch(changeList(data, total));
    }
  } catch (error) {}
};

/**
 * 核销
 */
export const onOrderConfirm = (ordersn, cb) => async dispatch => {
  try {
    const res = await orderConfrim({ ordersn });
    if (res.code === 0) {
      message.success('核销成功');
      cb();
    } else message.error(res.msg);
  } catch (error) {}
};
