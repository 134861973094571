import React, { PureComponent, Suspense } from "react";
import Loading from "../Loading";

export default WrappedComponent => {
  class NewComponent extends PureComponent {
    render() {
      return (
        <Suspense
          fallback={
            <div
              style={{
                position: "fixed",
                left: 0,
                top: 0,
                zIndex: "999999",
                backgroundColor: "rgba(255,255,255,.6)",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Loading size={200} />
            </div>
          }
        >
          <WrappedComponent {...this.props} />
        </Suspense>
      );
    }
  }
  return NewComponent;
};
