import {
  EXPENSES_DATA,
  DATE_RANGE,
  STORE_ID,
  SEARCH_CONDITION
} from "./constants";

export const INITIAL_STATE = {
  customerData: null,
  storeId: undefined,
  dateRange: undefined,
  searchCondition: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORE_ID:
      return {
        ...state,
        storeId: action.val
      };
    case EXPENSES_DATA:
      return {
        ...state,
        customerData: action.val && { ...action.val }
      };
    case DATE_RANGE:
      return {
        ...state,
        dateRange: action.val && [...action.val]
      };
    case SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: action.val && { ...action.val }
      };
    default:
      return state;
  }
};
