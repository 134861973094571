import request from "./request";

export const userLogin = params => {
  return request({
    url: "/api/login",
    method: "post",
    data: params
  });
};

export const userMenu = () => {
  return request({
    url: "/api/menu",
    method: "post"
  });
};

export const getAllStore = () => {
  return request({
    url: "/api/index",
    method: "post"
  });
};

export const getCustomerBase = data => {
  return request({
    url: "/api/customer",
    method: "post",
    data
  });
};

export const getStoreExpenses = data => {
  return request({
    url: "/api/expenditure_count",
    method: "post",
    data
  });
};

export const getIntegralOrder = data => {
  return request({
    url: "/api/order_list",
    method: "post",
    data
  });
};
