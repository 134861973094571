import { getStoreExpenses } from "@/api";
import { EXPENSES_DATA, DATE_RANGE, SEARCH_CONDITION ,STORE_ID} from "./constants";

export const changeExpensesData = val => ({
  type: EXPENSES_DATA,
  val
});

export const changeStoreId = val => ({
  type: STORE_ID,
  val
});

export const changeDateRange = val => ({
  type: DATE_RANGE,
  val
});

export const changeSearchCondition = val => ({
  type: SEARCH_CONDITION,
  val
});

export const getCustomerPageData = params => async dispatch => {
  const res = await getStoreExpenses(params);
  if (res.code === 0) {
    const data = res.data;
    const arr = [];
    data.day.map(item => {
      arr.push({
        time: item.time,
        type: "门店支出",
        sum: item.sum
      });
      arr.push({
        time: item.time,
        type: "营业费用",
        sum: item.business
      });
      arr.push({ time: item.time, type: "人工费用", sum: item.Labor });
      arr.push({ time: item.time, type: "营销费用", sum: item.marketing });
      arr.push({ time: item.time, type: "采购费用", sum: item.purchase });
      arr.push({ time: item.time, type: "密室维护费用", sum: item.maintain });
      return item;
    });
    data.day = arr;
    dispatch(changeExpensesData(res.data));
  }
};
