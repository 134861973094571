import request from "@/api/request";

/**
 * 主题详情列表
 */
export const getThemeDetailList = data => {
  return request({
    url: "/api/theme_list",
    method: "post",
    data
  });
};

/**
 * 修改
 */
export const updateTheme = data => {
  return request({
    url: "/api/sales_record",
    method: "post",
    data
  });
};