export const SELECTED_ROW_KEYS = "USER_MANAGE/SELECTED_ROW_KEYS";
export const SEARCH_PARAMS = "USER_MANAGE/SEARCH_PARAMS";
export const SEARCH_CONDITION = "USER_MANAGE/SEARCH_CONDITION";
export const DETAIL_SEARCH_CONDITION = "USER_MANAGE/DETAIL_SEARCH_CONDITION";
export const DETAIL_SEARCH_PARAMS = "USER_MANAGE/DETAIL_SEARCH_PARAMS";
export const DETAIL_PAGE = "USER_MANAGE/DETAIL_PAGE";
export const DETAIL_ROWS = "USER_MANAGE/DETAIL_ROWS";
export const LIST = "USER_MANAGE/LIST";
export const SHOW_EDIT = "USER_MANAGE/SHOW_EDIT";
export const DETAIL_VALUE = "USER_MANAGE/DETAIL_VALUE";
export const PAGE = "USER_MANAGE/PAGE";
export const ROWS = "USER_MANAGE/ROWS";
export const FREE_SETTING = "USER_MANAGE/FREE_SETTING";
